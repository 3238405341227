<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">FAQs</h1>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'FAQPage',
}
</script>
